import { SwiperOptions } from "swiper";

const swiperBaseOptions: SwiperOptions = {
  mousewheel: {
    forceToAxis: true,
  },
  touchEventsTarget: "wrapper", // fixes issue: https://github.com/nolimits4web/swiper/issues/2428#issuecomment-404455734
};

export default swiperBaseOptions;
