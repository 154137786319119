export default class TwoDPoint {
  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  x: number;

  y: number;
}
