import Swiper, {
  A11y,
  Autoplay,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper";
import swiperBaseOptions from "../shared/swiperOptions";
import { waitForElement } from "../shared/utilities";

class SuccessStories {
  element: HTMLElement;

  swiper: Swiper;

  init = (svg: HTMLElement) => {
    this.element = svg;
    this.swiper = new Swiper("#success-stories__swiper__container", {
      ...swiperBaseOptions,
      direction: "horizontal",
      speed: 1000,
      spaceBetween: 30,
      slidesPerView: "auto",
    });

    this.element.classList.add("bn-fade-block");
  };
}
Swiper.use([A11y, Autoplay, Mousewheel, Navigation, Pagination]);
waitForElement<HTMLElement>(".success-stories").then((el: HTMLElement) => {
  new SuccessStories().init(el);
});
