/* eslint-disable no-param-reassign */
import CallbackOnScroll from "../../callbackOnScroll";
import Noise from "./noise";
import Bubble from "./bubble";
import randomBetween from "./bubbleHelpers";
import { waitForElement } from "../../utilities";

type resizeCallBack = () => void;
class Bubbles {
  MAX_SCROLL_SPEED = 0.9;

  SCROLL_SPPED_MULTIPLIER = 20;

  NOISE_AMOUNT_MULITPLIER = 250;

  NOISE_SPEED = 0.003;

  BUBBLE_SIZE_RANGE = 0.3;

  MEDIA_STOPS = { small: 576, medium: 992 };

  COLUMN_WIDTHS = { small: 150, medium: 175, large: 280 };

  NUMBER_OF_ROWS = 3;

  HEIGHT_PADDING = 130;

  MAX_FPS = 1000 / 60;

  noiseAmount: number;

  scrollSpeed: number;

  columnWidth: number;

  clientWidth: number;

  noise: Noise;

  bubbles: Array<Bubble>;

  wrapperElement: HTMLElement;

  bubblesElement: HTMLElement;

  rowStep: number;

  animationId: number | null;

  callBackOnScroll: CallbackOnScroll;

  runAnimation = false;

  init(wrapper: HTMLElement) {
    this.bubbles = [];
    this.noise = new Noise();
    this.noise.init();
    this.wrapperElement = wrapper;

    this.bubblesElement = document.querySelector(".bubbles") as HTMLElement;

    window.addEventListener("resize", () => {
      if (this.animationId !== null) {
        cancelAnimationFrame(this.animationId as number);
        this.animationId = null;

        Bubbles.debounce(() => {
          if (this.animationId === null) {
            this.layout();
            this.update();
          }
        }, 500)();
      }
    });
    this.callBackOnScroll = new CallbackOnScroll({
      targets: this.wrapperElement,
      options: {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
        callBack: (inView) => {
          this.runAnimation = inView;
          this.update();
        },
      },
    });
    this.layout();
    this.callBackOnScroll.init();
  }

  static debounce(func: resizeCallBack, timeout: number): resizeCallBack {
    let timer: NodeJS.Timeout;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func();
      }, timeout);
    };
  }

  getColumnWidth(): number {
    if (
      this.clientWidth < this.MEDIA_STOPS.medium &&
      this.clientWidth > this.MEDIA_STOPS.small
    ) {
      return this.COLUMN_WIDTHS.small;
    }
    if (this.clientWidth < this.MEDIA_STOPS.medium) {
      return this.COLUMN_WIDTHS.medium;
    }

    return this.COLUMN_WIDTHS.large;
  }

  layout(): void {
    // only do the layout if the window has changed size
    // mobile browsers call resize while scrolling
    if (this.clientWidth !== this.wrapperElement.clientWidth) {
      const bubbleElements = Array.from(
        document.querySelectorAll<HTMLElement>(".bubble__container"),
      );
      this.clientWidth = this.wrapperElement.clientWidth;
      this.columnWidth = this.getColumnWidth();

      // if we dont have enough bubbles to fill the screen width
      if (
        this.clientWidth >
        this.columnWidth * (bubbleElements.length / this.NUMBER_OF_ROWS)
      ) {
        // repeat bubbles until we do fill the screen
        const spacetoFill =
          this.clientWidth -
          this.columnWidth * (bubbleElements.length / this.NUMBER_OF_ROWS) +
          this.columnWidth;

        // make sure we fill an extra column of bubbles offscreen to smooth transitions
        const bubblesRequired = Math.ceil(
          (spacetoFill / this.columnWidth) * this.NUMBER_OF_ROWS,
        );

        for (let i = 0; i < bubblesRequired; i += 1) {
          const newElement = bubbleElements[
            i % bubbleElements.length
          ].cloneNode(true) as HTMLElement;

          this.bubblesElement.appendChild(newElement);
          bubbleElements.push(newElement);
        }
      }

      // init our bubble models
      this.bubbles = bubbleElements.map((element: HTMLElement) => {
        return new Bubble(
          0,
          0,
          randomBetween(1 - this.BUBBLE_SIZE_RANGE, 1 + this.BUBBLE_SIZE_RANGE),
          element,
          0,
        );
      });

      // calculate the various motion parameters based on the screen width
      this.scrollSpeed =
        this.clientWidth / this.columnWidth / this.SCROLL_SPPED_MULTIPLIER;
      if (this.scrollSpeed > this.MAX_SCROLL_SPEED) {
        this.scrollSpeed = this.MAX_SCROLL_SPEED;
      }
      this.noiseAmount = this.scrollSpeed * this.NOISE_AMOUNT_MULITPLIER;

      // layout the bubbles
      this.rowStep =
        (this.wrapperElement.clientHeight - this.HEIGHT_PADDING) /
        this.NUMBER_OF_ROWS;
      this.bubbles.forEach((bubble, index) => {
        bubble.element.classList.remove("fadeIn");
        bubble.initNoiseSeed();
        bubble.position.x =
          this.columnWidth *
          ((index % this.bubbles.length) / this.NUMBER_OF_ROWS);
        bubble.row = index % this.NUMBER_OF_ROWS;
      });
    }
  }

  updateBubble(bubble: Bubble) {
    // get our noise deltas
    bubble.noiseSeed.x += this.NOISE_SPEED;
    bubble.noiseSeed.y += this.NOISE_SPEED;
    const randomX = this.noise.noise(bubble.noiseSeed.x, bubble.noiseSeed.y);
    const randomY = this.noise.noise(bubble.noiseSeed.x, bubble.noiseSeed.y);

    bubble.positionDelta.x = randomX * this.noiseAmount;
    bubble.positionDelta.y = randomY * this.noiseAmount;

    bubble.position.x -= this.scrollSpeed;

    let bubblePosX = bubble.position.x + bubble.positionDelta.x - bubble.radius;

    // if the bubble is off the left of the screen wrap it round
    // and place it on the next available row

    if (bubblePosX < -bubble.radius * 2) {
      bubble.element.classList.remove("fadeIn");

      const lastBubble = this.bubbles.reduce((prev, current) => {
        if (current.position.x > prev.position.x) {
          return current;
        }
        return prev;
      });

      bubble.position.x =
        lastBubble.position.x + this.columnWidth / this.NUMBER_OF_ROWS;

      // workout which row the bubble should move to
      const lastRow = lastBubble.row;

      bubble.row = (lastRow + 1) % this.NUMBER_OF_ROWS;

      bubble.initNoiseSeed();

      bubblePosX = bubble.position.x + bubble.positionDelta.x - bubble.radius;
    }

    if (bubblePosX > -bubble.radius && bubblePosX < this.clientWidth) {
      // if the bubble can be seen then show and translate it
      if (!bubble.element.classList.contains("fadeIn")) {
        bubble.element.classList.add("fadeIn");
      }
    }
    const bubblePosY =
      bubble.row * this.rowStep +
      bubble.positionDelta.y -
      bubble.radius +
      this.HEIGHT_PADDING;

    bubble.element.style.transform = `translate(${bubblePosX}px, ${bubblePosY}px) scale(${bubble.scale})`;
  }

  update() {
    if (this.runAnimation) {
      this.bubbles.forEach((bubble) => this.updateBubble(bubble));
      this.animationId = requestAnimationFrame(this.update.bind(this));
    }
  }
}
waitForElement(".bubble__wrap").then(async (el: HTMLElement) => {
  new Bubbles().init(el);
});
