import TwoDPoint from "./twoDPoint";

export default class Bubble {
  position: TwoDPoint;

  positionDelta: TwoDPoint;

  noiseSeed: TwoDPoint;

  scale: number;

  radius: number;

  element: HTMLElement;

  row: number;

  constructor(x: number, y: number, s: number, el: HTMLElement, row: number) {
    this.element = el;
    this.position = new TwoDPoint(x, y);
    this.scale = s;

    this.radius = el.clientWidth / 2;
    this.positionDelta = new TwoDPoint(0, 0);
    this.row = row;

    this.initNoiseSeed();
  }

  initNoiseSeed(): void {
    this.noiseSeed = new TwoDPoint(
      Math.floor(Math.random() * 64000),
      Math.floor(Math.random() * 64000),
    );
  }
}
